import { AxiosError } from 'axios';

import debounce from 'bloko/common/debounce';

import { VoteType } from 'src/models/articleRating';
import { VoteButtonType } from 'src/pages/Article/components/Footer/DefaultFooter/Rating/VoteButton';
import { fetcher } from 'src/utils/fetcher';

const REMOTE = '/shards/article/rating';
const SEND_VOTE_INTERVAL_MS = 250;

declare global {
    interface FetcherPutApi {
        [REMOTE]: {
            body: void;
            queryParams: {
                articleId: string;
                voteState: VoteButtonType;
            };
            response: void;
        };
    }
    interface FetcherDeleteApi {
        [REMOTE]: {
            queryParams: {
                articleId: string;
            };
            response: void;
        };
    }
}

export const sendVote = debounce(
    (articleId: string, voteStateUpdated: VoteType, errorHandler: (error: AxiosError) => void): void => {
        if (voteStateUpdated === VoteType.NoVoted) {
            fetcher.delete(REMOTE, { params: { articleId } }).catch(errorHandler);
        } else {
            fetcher.put(REMOTE, undefined, { params: { articleId, voteState: voteStateUpdated } }).catch(errorHandler);
        }
    },
    SEND_VOTE_INTERVAL_MS
);
